.header {
    height: 315px;
    text-align: center;
    font-family: "Gotham A", "Gotham B";
    font-size: 48px;
    text-transform: uppercase;
    font-weight: 300;
    color: white;
    background-color: #6AE1C6;
}

.header-text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.header-caption {
    margin-top: 5px;
    font-family: "Gotham A", "Gotham B";
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
}
