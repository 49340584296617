strong {
    font-family: "Gotham A", "Gotham B";
    color: #777;
}

em {
    font-family: "Gotham A", "Gotham B";
}

p {
    font-family: "Gotham A", "Gotham B";
    color: #777;
}

.document-contents {
    font-family: "Gotham A", "Gotham B";
    color: #777;
}

.document-header {
    font-family: "Eveleth" !important;
    letter-spacing: 3px !important;
    color: #6AE1C6 !important;
}
