.not-found {
    position: relative;
    top: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background-color: #6AE1C6;
}

#not-found-container {
    position: relative;
    top: 50%;
    width: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
}

.not-found-header {
    font-family: "Eveleth";
    font-size: 11px;
    letter-spacing: 3px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 25px;
    align-content: center;
}

.not-found-body {
    font-family: "Gotham A", "Gotham B";
    font-size: 32px;
    font-weight: 500;
    color: white;
    align-content: center;
}

.center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

@media (max-width: 768px) {
    #not-found-container {
        width: 65%;
    }
    .not-found-body {
        font-size: 26px;
    }
    .not-found-header {
        font-size: 9px;
    }
}
