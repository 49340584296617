.service-card {
    display: flex;
    flex-direction: column;
    height: 419px;
    max-width: 386px;
    width: 100%;
    background: #F7F7F7;
    border-radius: 8px;
    padding-left: 50px;
}

.service-card-wrapper {
    display: flex;
    flex-direction: column;
    height: 433px;
    border-style: solid;
    border-width: 3px;
    border-color: rgba(106, 225, 198, 0);
    max-width: 400px;
    width: 100%;
    border-radius: 10px;
    margin: 3px;
    padding: 4px;
    transition: border-color 300ms;
}

.service-card-wrapper:hover {
    border-color: rgba(106, 225, 198, 1);
    cursor: pointer;
}

.service-card-header {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
}

.service-card-header-title {
    color: #007589;
    font-size: 32px !important;
    margin-top: 50px !important;
    margin-bottom: 25px !important;
    margin-left: 5px !important;
}

.service-card-header-icon {
    margin-left: -15px;
    margin-bottom: 8px;
    align-self: flex-end;
    height: 92px;
    width: 92px;
}

.service-card-row {
    margin-bottom: 35px;
}

.service-card-row-heading {
    font-size: 16px;
    letter-spacing: 1.6px;
    font-weight: 500;
    color: #007589;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 5px;
}

.service-card-row-subheading {
    font-weight: 300;
    font-size: 18px;
    color: #4D4E56;
}

.service-card-body {

}

@media(max-width: 1290px) {
    .service-card {
        height: 325px;
        max-width: 325px;
        padding-left: 25px;
    }
    .service-card-wrapper {
        height: 339px;
        max-width: 339px;
    }
    .service-card-header {
        margin-left: -5px;
        padding-bottom: 5px;
    }
    .service-card-header-title {
        font-size: 27px !important;
        margin-top: 25px !important;
        margin-bottom: 20px !important;
        margin-left: 5px !important;
    }
    .service-card-header-icon {
        margin-bottom: 8px;
        align-self: flex-end;
        margin-left: -5px;
        height: 60px;
        width: 60px;
    }
    .service-card-row {
        margin-bottom: 26px;
    }
    .service-card-row-heading {
        font-size: 15px;
        letter-spacing: 1.3px;
        padding-bottom: 5px;
        font-weight: 500;
    }
    .service-card-row-subheading {
        font-size: 16px;
    }
}

@media(max-width: 1000px) {

}

@media(max-width: 768px) {
    .service-card-wrapper {
        margin: 0 -7px 11px -7px;
        width: calc(100% + 14px);
    }
}

@media(max-width: 576px) {

}
