div.footer {
 color: #f5f5f5;
 font-family: 'Gotham A', 'Gotham B';
 text-align: center;
 padding-top: 15px;
 padding-bottom: 40px;
 width: 100%;
}

div.footer-background {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #827c80;
  z-index: -5;
}

div.footer-cover {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #6AE1C6;
    z-index: -4;
}

div.footer-wrapper {
    position: fixed;
    color: #f5f5f5;
    font-family: 'Gotham A', 'Gotham B';
    text-align: center;
    width: 100%;
    z-index: -5;
}

#footer-container {
  width: auto;
  height: auto;
  max-width: 750px;
  margin: auto;
}

div.oos-logo {
  margin-bottom: 5px;
}

.contact {
  margin-bottom: 15px;
}

.about-us {
  margin-bottom: 20px;
}

.contact-href{
    font-weight: 400 !important;
    color: lightgrey !important;
}

.contact-normal{
  font-weight: 400 !important;
  color: lightgrey !important;
}

.privacy-policy-href {
  color: #f5f5f5 !important;
  margin: 0 !important;
  height: 15px !important;
}

.privacy-policy-href:hover {
  color: #f5f5f5 !important;
  cursor: pointer;
  text-decoration: underline;
}

.footer-divider {
  padding-left: 10px;
  padding-right: 10px;
}

.contact-href:hover {
    color: #f5f5f5 !important;
    font-weight: 400 !important;
}

.contact-type {
    margin-right: 10px;
}


.footer-contact {
  width: 100% !important;
}

.logos {
  margin-bottom: 15px;
}

.logo {
  width: 24px;
  margin: 6px;
}
#instagram {
  width: 22px;
}
#linkedin {
  width: 22px;
  margin-top: 4.28px;
  margin-left: 8px;
}

/*#oos-logo {
  margin: 10px;
  margin-top: 5px;
  width: 60px;
}*/

div.copyright {
  font-size: 11px;
}

#about-us-summary {
  margin-top: 20px;
  font-weight: 300;
  font-style: italic;
}
