.slogan-item {
    padding-bottom: 40px;
    padding-top: 40px;

}

.slogan-list-item {
    list-style: none;
    border-bottom: 1px solid #C9C9C7;
}

.slogan-list-item:last-child {
    border: none;
}

.slogan-item-heading {
    font-family: "Gotham A", "Gotham B";
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 15px;
    color: #007589;
    letter-spacing: 0px;
}

.slogan-item-text {
    font-family: "Gotham A", "Gotham B";
    color: white;
    font-size: 18px;
    color: #4D4E56;
    line-height: 150%;
    font-weight: 300;
}
