.privacy-policy {
    position: relative;
    top: 0;
    width: 100%;
    background-color: #6AE1C6;
}

.privacy-policy-header {
    height: 315px;
    text-align: center;
    font-family: "Gotham A", "Gotham B";
    font-size: 32px;
    font-weight: 500;
    color: white;
}

#privacy-policy-header-text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

#privacy-policy-subheader-text {
    margin-top: 5px;
    font-family: "Eveleth";
    font-size: 11px;
    letter-spacing: 3px;
    color: rgba(255, 255, 255, 0.6);
}

.underline {
    border-bottom: 2.5px solid #336f81;
}

.privacy-policy-body {
    background-color: white;
    padding: 65px;
    padding-left: 10%;
    padding-right: 10%;
}

.footer-section {
    position:absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

body {
    position: relative;

    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
}
