#section6 {
    position: relative;
    top: 500%
}

#footerSection {
    position: absolute;
    left: 0;
    height: auto;
    width: 100%;
}

.home-page {
    overflow: visible;
}

/* Slideshow arrows */
.react-slideshow-container .nav {
    backdrop-filter: blur(10px) !important;
    background-color: rgba(255, 255, 255, 0.75) !important;
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    margin: 0 !important;
    position: absolute;
}

.react-slideshow-container .nav:last-of-type {
    right: 30px !important;
}

.react-slideshow-container .nav:first-of-type {
    left: 30px !important;
}

.react-slideshow-container .nav span {
    border-style: unset !important;
    border-color: transparent !important;
}

.react-slideshow-container .nav span {
    position: relative !important;
    background-color: #007589 !important;
    text-align: left !important;
}

.react-slideshow-container .nav span:before,
.react-slideshow-container .nav span:after {
    content: '' !important;
    position: absolute !important;
    background-color: inherit !important;
}

.react-slideshow-container .nav span,
.react-slideshow-container .nav span:before,
.react-slideshow-container .nav span:after {
    width:  11px !important;
    height: 11px !important;
    border-top-right-radius: 30% !important;
}

.react-slideshow-container .nav span {
    transform: rotate(90deg) skewX(-30deg) scale(1,.866) !important;
    margin-top: 5px !important;
    margin-left: 4px !important;
}

.react-slideshow-container .nav:last-of-type span {
    transform: rotate(-90deg) skewX(-30deg) scale(1,.866) !important;
    margin: 0 5px 6px 0 !important;
}

.react-slideshow-container .nav span:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%) !important;
}

.react-slideshow-container .nav span:after {
    transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%) !important;
}


@media (max-width: 991px) {
    .react-slideshow-container .nav:last-of-type {
        bottom: 40px !important;
    }

    .react-slideshow-container .nav:first-of-type {
        bottom: 40px !important;
    }
}

@media(max-width: 1000px) {
    #hero-highlight {
        font-size: 36px !important;
    }
}

@media(max-width: 768px) {
    #hero-highlight {
        font-size: 28px !important;
    }
}

@media(max-width: 576px) {
    #hero-highlight {
        font-size: 22px !important;
    }
}

@media(max-width: 400px) {
    #hero-highlight {
        font-size: 19px !important;
    }
}

.hero-headline strong {
    font-weight: 500 !important;
    color: #007589 !important;
}

.hero-headline p,
.hero-headline h1,
.hero-headline h2,
.hero-headline h3,
.hero-headline h4,
.hero-headline h5,
.hero-headline h6 {
    font-weight: 300 !important;
    color: #007589 !important;
}
