@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 600;
    src: url("gotham/GothamRnd-Bold.otf") format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 600;
    src: url("gotham/GothamRnd-BoldItal.otf") format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 400;
    src: url("gotham/GothamRnd-BookItal.otf") format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    src: url("gotham/GothamRnd-Book.otf") format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 300;
    src: url("gotham/GothamRnd-Light.otf") format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 300;
    src: url("gotham/GothamRnd-LightItal.otf") format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 500;
    src: url("gotham/GothamRnd-MedItal.otf") format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src: url("gotham/GothamRnd-Medium.otf") format('opentype');
}

@font-face {
    font-family: 'Eveleth';
    font-style: normal;
    font-weight: normal;
    src: url("eveleth/EvelethClean.otf") format('opentype'),
    url("eveleth/evelethclean-webfont.ttf") format('truetype');
}
